import { TimeZone } from "@marathon/common/helpers/timeZoneHelper";
import { getDistanceInMiles } from "../helpers/mapHelper";
import { shouldBeUnreachable } from "../helpers/typesHelper";
import { Customer, LeadOrigin } from "./Customer";
import { CsatRequestConfiguration } from "./Configuration";

enum HubGroup {
    AllPacific = "all-pacific-hubs",
    AllMountain = "all-mountain-hubs",
    AllCentral = "all-central-hubs",
    AllArizona = "all-arizona-hubs",
    AllEastern = "all-eastern-hubs",
    AllHubs = "all-hubs"
}

export const VCARD_MEDIA_TYPE = "text/x-vcard";

interface HubData {
    acronym: string,
    full_address: string,
    name: string,
    phone_number: string,
    place_id: string,
    vertices: Vertex[],
    coordinates: Vertex,
    pricing_preset_id: string
    time_zone: TimeZone,
    maximum_distance?: number,
    maximum_drive_time?: number,
    prices_include_tax?: true,
    first_time_customer_discount?: {
        value: number,
        is_enabled: boolean
    }
    goal: {
        value: number,
        thismn: number
    },
    google_feedback_url: string,
    square_location_id: string,
    vcard_url: string,
    hide_from_online_booking?: true,
    google_csat_feedback_url: string,
    extra_csat_feedback_urls?: CsatFeedbackURL[]
}

interface Vertex {
    lat: number,
    lng: number
}

interface Hub extends HubData {
    id: string
}

interface CsatFeedbackURL {
    origin: LeadOrigin,
    url?: string
}

class Hub {
    constructor(id: string, data: HubData) {
        this.id = id;
        Object.assign(this, data);
    }
    toData(): HubData {
        const { id, ...data } = this;
        return data;
    }
    getFormattedPhoneNumber() {
        const match = this.phone_number.match(/(\d{3})(\d{3})(\d{4})/);
        if (!match) {
            return this.phone_number;
        }
        return `(${match[1]}) ${match[2]}-${match[3]}`;
    }
    static defaultSalesGoal() {
        return {
            value: 0,
            thismn: 1
        };
    }
    npsFeedbackUrl() {
        return `https://www.barkbus.com/feedback/${this.acronym.toLowerCase()}`;
    }
    getDistanceFromHubInMiles(destinationCoordinates: Vertex) {
        return getDistanceInMiles(this.coordinates, destinationCoordinates);
    }
    getCsatFeedbackUrl(customer: Customer, configuration: CsatRequestConfiguration, hubs: Hub[]) {
        const forcedHub = hubs.find(x => x.id === configuration.forced_feedback_hub_id);
        const toMatchByOriginUrls = [
            ...this.extra_csat_feedback_urls || [],
            { origin: LeadOrigin.google, url: this.google_csat_feedback_url },
            { origin: LeadOrigin.googleAd, url: this.google_csat_feedback_url }
        ];
        const matchedByOriginUrl = toMatchByOriginUrls.find(x => x.origin === customer.lead_info?.origin)?.url;
        return (
            matchedByOriginUrl ||
            forcedHub?.google_csat_feedback_url ||
            this.google_csat_feedback_url
        );
    }
    static customCsatLeadOrigins() {
        return [LeadOrigin.yelp, LeadOrigin.nextdoor, LeadOrigin.thumbtack];
    }
    static get group() {
        return {
            name: (group: HubGroup) => {
                return (
                    group
                        .split("-")
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                        .join(" ")
                );
            },
            timeZone: (group: HubGroup) => {
                switch (group) {
                    case HubGroup.AllHubs:
                        return TimeZone.PacificTime;
                    case HubGroup.AllPacific:
                        return TimeZone.PacificTime;
                    case HubGroup.AllMountain:
                        return TimeZone.MountainTime;
                    case HubGroup.AllCentral:
                        return TimeZone.CentralTime;
                    case HubGroup.AllArizona:
                        return TimeZone.ArizonaTime;
                    case HubGroup.AllEastern:
                        return TimeZone.EasternTime;
                    default:
                        shouldBeUnreachable(group);
                }
            }
        };
    }
    static get geofenceGroup() {
        return {
            name: (group: GeofenceGroup) => {
                if (group.length === 3) {
                    return group.toUpperCase();
                }
                else {
                    return (
                        group
                            .split("-")
                            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                            .join(" ")
                    );
                }
            },
            zoom: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                        return 8.5;
                    case GeofenceGroup.orangeCounty:
                        return 9;
                    case GeofenceGroup.sanDiego:
                        return 9;
                    case GeofenceGroup.bayArea:
                        return 8;
                    case GeofenceGroup.sacramento:
                        return 9;
                    case GeofenceGroup.dallas:
                        return 9;
                    case GeofenceGroup.houston:
                        return 9;
                    case GeofenceGroup.phoenix:
                        return 9;
                    case GeofenceGroup.atlanta:
                        return 10.5;
                    case GeofenceGroup.charlotte:
                        return 9;
                    case GeofenceGroup.dmv:
                        return 9;
                    case GeofenceGroup.colorado:
                        return 8;
                    case GeofenceGroup.southFlorida:
                        return 8;
                    default:
                        shouldBeUnreachable(group);
                }
            },
            center: (group: GeofenceGroup) => {
                switch (group) {
                    case GeofenceGroup.losAngeles:
                        return { lat: 33.9122, lng: -118.5737 };
                    case GeofenceGroup.orangeCounty:
                        return { lat: 33.7175, lng: -117.8311 };
                    case GeofenceGroup.sanDiego:
                        return { lat: 33.0157, lng: -117.1611 };
                    case GeofenceGroup.bayArea:
                        return { lat: 37.7749, lng: -121.8194 };
                    case GeofenceGroup.sacramento:
                        return { lat: 38.5816, lng: -121.2944 };
                    case GeofenceGroup.dallas:
                        return { lat: 32.9267, lng: -96.8870 };
                    case GeofenceGroup.houston:
                        return { lat: 29.7604, lng: -95.5998 };
                    case GeofenceGroup.phoenix:
                        return { lat: 33.3884, lng: -111.8500 };
                    case GeofenceGroup.atlanta:
                        return { lat: 33.8290, lng: -84.3880 };
                    case GeofenceGroup.charlotte:
                        return { lat: 35.2796, lng: -80.8382 };
                    case GeofenceGroup.dmv:
                        return { lat: 38.8951, lng: -77.2364 };
                    case GeofenceGroup.colorado:
                        return { lat: 39.7392, lng: -104.9903 };
                    case GeofenceGroup.southFlorida:
                        return { lat: 26.117018, lng: -80.1374448 };
                    default:
                        shouldBeUnreachable(group);
                }
            }
        };
    }
}

enum GeofenceGroup {
    losAngeles = "los-angeles",
    orangeCounty = "orange-county",
    sanDiego = "san-diego",
    bayArea = "bay-area",
    sacramento = "sacramento",
    colorado = "colorado",
    dallas = "dallas",
    houston = "houston",
    phoenix = "phoenix",
    charlotte = "charlotte",
    dmv = "dmv",
    atlanta = "atlanta",
    southFlorida = "south-florida"
}

export { Hub, HubGroup, GeofenceGroup, CsatFeedbackURL };
export type { HubData, Vertex };